import { createAsyncThunk } from "@reduxjs/toolkit";

import { AsyncThunkConfig } from "services/store/types";
import {
  FetchReportPayload,
  FetchReportResponse,
  fetchReport,
} from "api/reports";
import {
  SendReportPayload,
  SendReportResponse,
  sendReport as sendReportApi,
} from "api/mails";

export const getReport = createAsyncThunk<
  FetchReportResponse,
  FetchReportPayload,
  AsyncThunkConfig
>("reports/getReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await fetchReport<FetchReportResponse>(payload);

    return response.data;
  } catch (err) {
    return rejectWithValue("Ошибка получения отчета");
  }
});

export const sendReport = createAsyncThunk<
  SendReportResponse,
  SendReportPayload,
  AsyncThunkConfig
>("reports/sendReport", async (payload, { rejectWithValue }) => {
  try {
    const response = await sendReportApi<SendReportResponse>(payload);

    return response.data;
  } catch (err) {
    return rejectWithValue("Ошибка отправки отчета");
  }
});
