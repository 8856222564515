import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { DefaultRootState } from "react-redux";
import {
  IDictionariesVacancies,
  IDirectoryState,
  IDirectoryTreeItem,
  IStatus,
} from "./types";
import { client as apollo } from "graphql/apollo";
import { RootState } from "../../rootReducer";
import { directoryToCollection } from "common/utils";
import {
  CitizenshipType,
  CityInputType,
  CityType,
  ClaimCategoriesType,
  ClaimStatusesType,
  DepartmentForm,
  DepartmentType,
  DriverLicenseType,
  EmployerType,
  EmploymentType,
  ExperienceType,
  LanguageLevelType,
  MetroType,
  MutationUpdatePositionCountArgs,
  NationalityType,
  PositionInputType,
  PositionType,
  RefusalInputType,
  RefusalReasonType,
  SourceType,
  SpecializationType,
  UserRolesType,
  VacancyRelevanceModelType,
  VacancyStatesType,
  VacancyStatusesType,
  VacancyTagType,
} from "graphql/types/types";
import { CREATE_REFUSAL } from "graphql/requests/mutation/createRefusal";
import { DELETE_REFUSAL } from "graphql/requests/mutation/deleteRefusal";
import { cloneDeep } from "lodash";
import { CREATE_POSITION } from "graphql/requests/mutation/createPosition";
import { DELETE_POSITION } from "graphql/requests/mutation/deletePosition";
import { DELETE_DEPARTMENT } from "graphql/requests/mutation/deleteDepartment";
import { CREATE_DEPARTMENT } from "graphql/requests/mutation/createDepartment";
import { CREATE_CITY } from "graphql/requests/mutation/createCity";
import { DELETE_CITY } from "graphql/requests/mutation/deleteCity";
import toaster from "components/UI/Notifications/Notification";
import { parseTree, parseTreeRole } from "common/helpers/treeParser";
import axios from "axios";
import { camelize } from "common/helpers/keyConverter";
import { POSITIONS_DICTIONARIES } from "graphql/requests/query/positionsDictionaries";
import { NATIONALITIES_DICTIONARIES } from "graphql/requests/query/nationalitiesDictionaries";

export const initialState: IDirectoryState = {
  categories: [], //typeEmployment
  positions: [],
  employers: [],
  vacancyStatuses: [],
  replyStatuses: [],
  claimStatuses: [],
  nationalities: [],
  nationalitiesVacancy: [],
  dictionariesVacancies: {},
  refusalReasons: [],
  claimCategories: [],
  claimSource: [],
  departments: [],
  cities: [],
  isLoading: false,
  userRoles: [],
  vacancyTags: [],
  vacancyStates: [],
  vacancyRelevance: [],
  driverLicense: [],
  experience: [],
  professionalRoles: [],
  areas: [],
  languageLevel: [],
  metro: [],
  employmentAvito: [],
  professions: [],
  registrationMethodAvito: [],
  bonusesAvito: [],
  scheduleHH: [],
  employmentHH: [],
};
export const directoryModule = createSlice({
  name: "directory",
  initialState,
  reducers: {
    setCategories(
      state: IDirectoryState,
      { payload }: PayloadAction<EmploymentType[]>
    ) {
      state.categories = payload;
    },
    setPositions(
      state: IDirectoryState,
      { payload }: PayloadAction<PositionType[]>
    ) {
      state.positions = payload;
    },
    setEmployers(
      state: IDirectoryState,
      { payload }: PayloadAction<EmployerType[]>
    ) {
      state.employers = camelize(payload) as EmployerType[];
    },
    setEmployments(
      state: IDirectoryState,
      { payload }: PayloadAction<EmploymentType[]>
    ) {
      state.categories = payload;
    },
    setVacancyStatuses(
      state: IDirectoryState,
      { payload }: PayloadAction<VacancyStatusesType[]>
    ) {
      state.vacancyStatuses = payload;
    },
    setRefusalReasons(
      state: IDirectoryState,
      { payload }: PayloadAction<RefusalReasonType[]>
    ) {
      state.refusalReasons = payload;
    },
    setReplyStatuses(
      state: IDirectoryState,
      { payload }: PayloadAction<IStatus[]>
    ) {
      state.replyStatuses = payload;
    },
    setClaimStatuses(
      state: IDirectoryState,
      { payload }: PayloadAction<ClaimStatusesType[]>
    ) {
      state.claimStatuses = payload;
    },
    setNationalities(
      state: IDirectoryState,
      { payload }: PayloadAction<CitizenshipType[]>
    ) {
      state.nationalities = payload;
    },
    setNationalitiesVacancy(
      state: IDirectoryState,
      { payload }: PayloadAction<NationalityType[]>
    ) {
      state.nationalitiesVacancy = payload;
    },
    setDictionariesVacancies(
      state: IDirectoryState,
      { payload }: PayloadAction<IDictionariesVacancies>
    ) {
      state.dictionariesVacancies = payload;
    },
    setClaimCategory(
      state: IDirectoryState,
      { payload }: PayloadAction<ClaimCategoriesType[]>
    ) {
      state.claimCategories = payload;
    },
    setClaimSource(
      state: IDirectoryState,
      { payload }: PayloadAction<SourceType[]>
    ) {
      state.claimSource = payload;
    },
    setDepartment(
      state: IDirectoryState,
      { payload }: PayloadAction<DepartmentType[]>
    ) {
      state.departments = camelize(payload) as DepartmentType[];
    },
    setCities(state: IDirectoryState, { payload }: PayloadAction<CityType[]>) {
      state.cities = camelize(payload) as CityType[];
    },
    setUserRoles(
      state: IDirectoryState,
      { payload }: PayloadAction<UserRolesType[]>
    ) {
      state.userRoles = payload;
    },
    setLoading(state: IDirectoryState, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setVacancyTags(
      state: IDirectoryState,
      { payload }: PayloadAction<VacancyTagType[]>
    ) {
      state.vacancyTags = camelize(payload) as VacancyTagType[];
    },
    setVacancyStates(
      state: IDirectoryState,
      { payload }: PayloadAction<VacancyStatesType[]>
    ) {
      state.vacancyStates = payload;
    },
    setVacancyRelevance(
      state: IDirectoryState,
      { payload }: PayloadAction<VacancyRelevanceModelType[]>
    ) {
      state.vacancyRelevance = payload;
    },
    setVacancyDriverLicense(
      state: IDirectoryState,
      { payload }: PayloadAction<DriverLicenseType[]>
    ) {
      state.driverLicense = payload;
    },
    setVacancyExperience(
      state: IDirectoryState,
      { payload }: PayloadAction<ExperienceType[]>
    ) {
      state.experience = payload;
    },
    setVacancySpecialization(
      state: IDirectoryState,
      { payload }: PayloadAction<SpecializationType[]>
    ) {
      const preparedData = camelize(payload) as IDirectoryTreeItem[];
      state.professionalRoles = parseTreeRole(preparedData, 0, 1);
    },
    setVacancyAreas(
      state: IDirectoryState,
      { payload }: PayloadAction<SpecializationType[]>
    ) {
      const preparedData = camelize(payload) as IDirectoryTreeItem[];
      state.areas = parseTree(preparedData);
    },
    setVacancyLanguageLevel(
      state: IDirectoryState,
      { payload }: PayloadAction<LanguageLevelType[]>
    ) {
      state.languageLevel = payload;
    },
    setMetro(state: IDirectoryState, { payload }: PayloadAction<MetroType[]>) {
      state.metro = payload;
    },
    setEmploymentAvito(state, { payload }: PayloadAction<EmploymentType[]>) {
      state.employmentAvito = payload;
    },
    setProfessions(state, { payload }: PayloadAction<EmploymentType[]>) {
      state.professions = payload;
    },
    setRegistrationMethod(state, { payload }: PayloadAction<EmploymentType[]>) {
      state.registrationMethodAvito = payload;
    },
    setBonusesAvito(state, { payload }: PayloadAction<EmploymentType[]>) {
      state.bonusesAvito = payload;
    },
    setScheduleHH(state, { payload }: PayloadAction<EmploymentType[]>) {
      state.scheduleHH = payload;
    },
    setEmploymentHH(state, { payload }: PayloadAction<EmploymentType[]>) {
      state.employmentHH = payload;
    },
    setPositionCountPeople(
      state: IDirectoryState,
      { payload }: PayloadAction<MutationUpdatePositionCountArgs>
    ) {
      const position = state.positions.find((pos) => pos.id === payload.id);
      if (!position) return;
      position.countPeople = payload.count;
    },
  },
});

// * mutations
export const {
  setCategories,
  setPositions,
  setEmployers,
  setVacancyStatuses,
  setReplyStatuses,
  setClaimStatuses,
  setNationalities,
  setRefusalReasons,
  setClaimCategory,
  setClaimSource,
  setDepartment,
  setCities,
  setLoading,
  setUserRoles,
  setVacancyTags,
  setVacancyStates,
  setVacancyRelevance,
  setVacancyDriverLicense,
  setVacancyExperience,
  setVacancySpecialization,
  setVacancyAreas,
  setVacancyLanguageLevel,
  setMetro,
  setEmploymentAvito,
  setProfessions,
  setRegistrationMethod,
  setBonusesAvito,
  setScheduleHH,
  setEmploymentHH,
  setPositionCountPeople,
  setNationalitiesVacancy,
} = directoryModule.actions;

// * getters
export const directory = (state: RootState) => state.directory;
export const categories = (state: RootState) => state.directory.categories;
export const positions = (state: RootState) => state.directory.positions;
export const employers = (state: RootState) => state.directory.employers;
export const vacancyStatuses = (state: RootState) =>
  state.directory.vacancyStatuses;
export const replyStatuses = (state: RootState) =>
  state.directory.replyStatuses;
export const claimStatuses = (state: RootState) =>
  state.directory.claimStatuses;
export const nationalities = (state: RootState) =>
  state.directory.nationalities;
export const refusalReasons = (state: RootState) =>
  state.directory.refusalReasons;
export const claimCategory = (state: RootState) =>
  state.directory.claimCategories;
export const departmentList = (state: RootState) => state.directory.departments;
export const cities = (state: RootState) => state.directory.cities;
export const metro = (state: RootState) => state.directory.metro;
export const userRoles = (state: RootState) => state.directory.userRoles;
export const isDictionaryLoading = (state: RootState) =>
  state.directory.isLoading;
export const vacancyTags = (state: RootState) => state.directory.vacancyTags;
export const vacancyStates = (state: RootState) =>
  state.directory.vacancyStates;
export const selectLanguageLevel = createSelector(
  directory,
  (state) => state.languageLevel
);
export const selectExperience = createSelector(
  directory,
  (state) => state.experience
);
export const selectDriverLicense = createSelector(
  directory,
  (state) => state.driverLicense
);
export const selectAreas = createSelector(directory, (state) => state.areas);
export const selectProfessionalRoles = createSelector(
  directory,
  (state) => state.professionalRoles
);
export const selectEmploymentAvito = createSelector(
  directory,
  (state) => state.employmentAvito
);
export const selectProfessions = createSelector(
  directory,
  (state) => state.professions
);
export const selectRegistrationMethodAvito = createSelector(
  directory,
  (state) => state.registrationMethodAvito
);
export const selectBonusesAvito = createSelector(
  directory,
  (state) => state.bonusesAvito
);
export const selectScheduleHH = createSelector(
  directory,
  (state) => state.scheduleHH
);
export const selectEmploymentHH = createSelector(
  directory,
  (state) => state.employmentHH
);
export const selectRefusalReasons = createSelector(
  directory,
  (state) => state.refusalReasons
);
export const selectRefusalReasonsByType = createSelector(
  selectRefusalReasons,
  (_: DefaultRootState, reasonType: string) => reasonType,
  (state, reasonType) => state.filter(({ type }) => type === reasonType)
);
export const selectNationalitiesVacancy = createSelector(
  directory,
  (state) => state.nationalitiesVacancy
);

//* collection
export const claimCategoryCollection = (state: RootState) =>
  directoryToCollection(state.directory.claimCategories);
export const userRoleCollection = (state: RootState) =>
  directoryToCollection(state.directory.userRoles);
export const claimSourceCollection = (state: RootState) =>
  directoryToCollection(state.directory.claimSource);
export const claimsSource = (state: RootState) => state.directory.claimSource;
export const department = (state: RootState) => state.directory.departments;
export const vacancyRelevance = (state: RootState) =>
  state.directory.vacancyRelevance;
export const dictionaryLoading = (state: RootState) =>
  state.directory.isLoading;
export const refusalReasonsCollection = (state: RootState) =>
  directoryToCollection(state.directory.refusalReasons);

// * actions

const S3_DIRECTORY_URL = process.env.REACT_APP_DICTIONARY_S3_URL;

export const getPositions = createAsyncThunk(
  "directory/positions",
  async (_, thunkApi) => {
    try {
      const res = await apollo.query({
        query: POSITIONS_DICTIONARIES,
      });
      thunkApi.dispatch(setPositions(res.data.dictionaries.positions));
    } catch (err) {
      console.error(err);
    }
  }
);
export const getEmployments = createAsyncThunk(
  "directory/employments",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${S3_DIRECTORY_URL}/dict_employment.json`);
      thunkApi.dispatch(setCategories(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getEmployers = createAsyncThunk(
  "directory/employer",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${S3_DIRECTORY_URL}/dict_employer.json`);
      thunkApi.dispatch(setEmployers(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getCitizenship = createAsyncThunk(
  "directory/citizenship",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${S3_DIRECTORY_URL}/dict_citizenship.json`);
      thunkApi.dispatch(setNationalities(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getRefusalReasons = createAsyncThunk(
  "directory/refusalReasons",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_refusal_reason.json`
      );
      thunkApi.dispatch(setRefusalReasons(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getSources = createAsyncThunk(
  "directory/sources",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${S3_DIRECTORY_URL}/dict_source.json`);
      thunkApi.dispatch(setClaimSource(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getCities = createAsyncThunk(
  "directory/cities",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${S3_DIRECTORY_URL}/dict_city.json`);
      thunkApi.dispatch(setCities(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getVacancyTags = createAsyncThunk(
  "directory/vacancyTag",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${S3_DIRECTORY_URL}/dict_vacancy_tag.json`);
      thunkApi.dispatch(setVacancyTags(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getDriverLicense = createAsyncThunk(
  "directory/driverLicense",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_driver_license.json`
      );
      thunkApi.dispatch(setVacancyDriverLicense(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getExperience = createAsyncThunk(
  "directory/experience",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${S3_DIRECTORY_URL}/dict_experience.json`);
      thunkApi.dispatch(setVacancyExperience(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getProfessionalRoles = createAsyncThunk(
  "directory/dict_professional_roles",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_professional_roles.json`
      );
      thunkApi.dispatch(setVacancySpecialization(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getAreas = createAsyncThunk(
  "directory/areas",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${S3_DIRECTORY_URL}/dict_areas.json`);
      thunkApi.dispatch(setVacancyAreas(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getLanguageLevels = createAsyncThunk(
  "directory/languageLevel",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_language_level.json`
      );
      thunkApi.dispatch(setVacancyLanguageLevel(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getVacancyStatuses = createAsyncThunk(
  "directory/vacancyStatuses",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_vacancy_statuses.json`
      );
      thunkApi.dispatch(setVacancyStatuses(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getReplyStatuses = createAsyncThunk(
  "directory/replyStatuses",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_reply_statuses.json`
      );
      thunkApi.dispatch(setReplyStatuses(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getClaimStatuses = createAsyncThunk(
  "directory/claimStatuses",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_claim_statuses.json`
      );
      thunkApi.dispatch(setClaimStatuses(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getClaimCategories = createAsyncThunk(
  "directory/claimCategories",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_claim_categories.json`
      );
      thunkApi.dispatch(setClaimCategory(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getDepartments = createAsyncThunk(
  "directory/departments",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${S3_DIRECTORY_URL}/dict_departments.json`);
      thunkApi.dispatch(setDepartment(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getUserRoles = createAsyncThunk(
  "directory/userRoles",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${S3_DIRECTORY_URL}/dict_user_roles.json`);
      thunkApi.dispatch(setUserRoles(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getVacancyStates = createAsyncThunk(
  "directory/vacancyStates",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_vacancy_states.json`
      );
      thunkApi.dispatch(setVacancyStates(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getVacancyRelevance = createAsyncThunk(
  "directory/vacancyRelevance",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_vacancy_relevance.json`
      );
      thunkApi.dispatch(setVacancyRelevance(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getMetro = createAsyncThunk(
  "directory/metro",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${S3_DIRECTORY_URL}/dict_metro.json`);
      thunkApi.dispatch(setMetro(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getEmploymentAvito = createAsyncThunk(
  "directory/employmentAvito",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_employment_avito.json`
      );
      thunkApi.dispatch(setEmploymentAvito(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getProfessions = createAsyncThunk(
  "directory/professions",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${S3_DIRECTORY_URL}/dict_professions.json`);
      thunkApi.dispatch(setProfessions(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getRegistrationMethodAvito = createAsyncThunk(
  "directory/registrationMethod",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_registration_method_avito.json`
      );
      thunkApi.dispatch(setRegistrationMethod(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getBonusesAvito = createAsyncThunk(
  "directory/bonusesAvito",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_bonuses_avito.json`
      );
      thunkApi.dispatch(setBonusesAvito(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getScheduleHH = createAsyncThunk(
  "directory/scheduleHH",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(`${S3_DIRECTORY_URL}/dict_schedule_hh.json`);
      thunkApi.dispatch(setScheduleHH(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);

export const getEmploymentHH = createAsyncThunk(
  "directory/employmentHH",
  async (_, thunkApi) => {
    try {
      const res = await axios.get(
        `${S3_DIRECTORY_URL}/dict_employment_hh.json`
      );
      thunkApi.dispatch(setEmploymentHH(res.data));
    } catch (err) {
      console.error(err);
    }
  }
);
export const getNationalitiesVacancy = createAsyncThunk(
  "directory/NationalitiesVacancy",
  async (_, thunkApi) => {
    try {
      const res = await apollo.query({
        query: NATIONALITIES_DICTIONARIES,
      });
      thunkApi.dispatch(
        setNationalitiesVacancy(res.data.dictionaries.nationalities)
      );
    } catch (err) {
      thunkApi.rejectWithValue(err);
    }
  }
);

export const getDictionaries = createAsyncThunk("directory", (_, thunkApi) => {
  thunkApi.dispatch(setLoading(true));
  const requestArr = [
    thunkApi.dispatch(getPositions()),
    thunkApi.dispatch(getEmployments()),
    thunkApi.dispatch(getEmployers()),
    thunkApi.dispatch(getCitizenship()),
    thunkApi.dispatch(getRefusalReasons()),
    thunkApi.dispatch(getSources()),
    thunkApi.dispatch(getCities()),
    thunkApi.dispatch(getVacancyTags()),
    thunkApi.dispatch(getDriverLicense()),
    thunkApi.dispatch(getExperience()),
    thunkApi.dispatch(getProfessionalRoles()),
    thunkApi.dispatch(getAreas()),
    thunkApi.dispatch(getLanguageLevels()),
    thunkApi.dispatch(getClaimCategories()),
    thunkApi.dispatch(getDepartments()),
    thunkApi.dispatch(getUserRoles()),
    thunkApi.dispatch(getVacancyStates()),
    thunkApi.dispatch(getVacancyRelevance()),
    thunkApi.dispatch(getVacancyStatuses()),
    thunkApi.dispatch(getReplyStatuses()),
    thunkApi.dispatch(getClaimStatuses()),
    thunkApi.dispatch(getMetro()),
    thunkApi.dispatch(getEmploymentAvito()),
    thunkApi.dispatch(getProfessions()),
    thunkApi.dispatch(getRegistrationMethodAvito()),
    thunkApi.dispatch(getBonusesAvito()),
    thunkApi.dispatch(getScheduleHH()),
    thunkApi.dispatch(getEmploymentHH()),
    thunkApi.dispatch(getNationalitiesVacancy()),
  ];
  Promise.all(requestArr)
    .catch((err) => {
      toaster.error({ title: err?.message || "Ошибка загрузки словарей" });
    })
    .finally(() => {
      thunkApi.dispatch(setLoading(false));
    });
});

export const createRefusal = createAsyncThunk(
  "directory/createRefusal",
  async (payload: RefusalInputType, thunkApi) => {
    try {
      const res = await apollo.mutate({
        mutation: CREATE_REFUSAL,
        variables: {
          form: payload,
        },
      });
      if (res?.data?.createRefusal) {
        const reasonRefusals = cloneDeep(
          // @ts-ignore
          thunkApi.getState()?.directory?.refusalReasons || []
        );
        const newItem = res?.data?.createRefusal;
        reasonRefusals.push(newItem);
        thunkApi.dispatch(setRefusalReasons(reasonRefusals));
        toaster.success({ title: "Изменения успешно сохранены" });
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const deleteRefusal = createAsyncThunk(
  "directory/deleteRefusal",
  async (payload: number, thunkApi) => {
    try {
      const res = await apollo.mutate({
        mutation: DELETE_REFUSAL,
        variables: {
          id: payload,
        },
      });
      if (res?.data?.deleteRefusal) {
        const reasonRefusals = cloneDeep(
          // @ts-ignore
          thunkApi.getState()?.directory?.refusalReasons || []
        );
        const newData = reasonRefusals.filter(
          (item: RefusalReasonType) => item.id !== payload
        );
        thunkApi.dispatch(setRefusalReasons(newData));
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const createPosition = createAsyncThunk(
  "directory/createPosition",
  async (payload: PositionInputType, thunkApi) => {
    try {
      const res = await apollo.mutate({
        mutation: CREATE_POSITION,
        variables: {
          form: payload,
        },
      });
      if (res?.data?.createPosition) {
        const positions = cloneDeep(
          // @ts-ignore
          thunkApi.getState()?.directory?.positions || []
        );
        const newItem = res?.data?.createPosition;
        positions.push(newItem);
        thunkApi.dispatch(setPositions(positions));
        toaster.success({ title: "Изменения успешно сохранены" });
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const deletePosition = createAsyncThunk(
  "directory/deletePosition",
  async (payload: number, thunkApi) => {
    try {
      const res = await apollo.mutate({
        mutation: DELETE_POSITION,
        variables: {
          id: payload,
        },
      });
      if (res?.data?.deletePosition) {
        const positions = cloneDeep(
          // @ts-ignore
          thunkApi.getState()?.directory?.positions || []
        );
        const newData = positions.filter(
          (item: PositionType) => item.id !== payload
        );
        thunkApi.dispatch(setPositions(newData));
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const deleteDepartment = createAsyncThunk(
  "directory/deleteDepartment",
  async (payload: number, thunkApi) => {
    try {
      const res = await apollo.mutate({
        mutation: DELETE_DEPARTMENT,
        variables: {
          id: payload,
        },
      });
      if (res?.data?.deleteDepartment) {
        // @ts-ignore
        const departments = cloneDeep(
          // @ts-ignore
          thunkApi.getState()?.directory?.departments || []
        );
        const newData = departments.filter(
          (item: DepartmentType) => item.id !== payload
        );
        thunkApi.dispatch(setDepartment(newData));
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const createDepartment = createAsyncThunk(
  "directory/createDepartment",
  async (payload: DepartmentForm, thunkApi) => {
    try {
      const res = await apollo.mutate({
        mutation: CREATE_DEPARTMENT,
        variables: {
          form: payload,
        },
      });
      if (res?.data?.createDepartment) {
        // @ts-ignore
        const departments = cloneDeep(
          // @ts-ignore
          thunkApi.getState()?.directory?.departments || []
        );
        const newItem = res?.data?.createDepartment;
        departments.push(newItem);
        thunkApi.dispatch(setDepartment(departments));
        toaster.success({ title: "Изменения успешно сохранены" });
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const createCity = createAsyncThunk(
  "directory/createCity",
  async (payload: CityInputType, thunkApi) => {
    try {
      const res = await apollo.mutate({
        mutation: CREATE_CITY,
        variables: {
          form: payload,
        },
      });
      if (res?.data?.createCity) {
        // @ts-ignore
        const cities = cloneDeep(thunkApi.getState()?.directory?.cities || []);
        const newItem = res?.data?.createCity;
        cities.push(newItem);
        thunkApi.dispatch(setCities(cities));
        toaster.success({ title: "Изменения успешно сохранены" });
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const deleteCity = createAsyncThunk(
  "directory/deleteCity",
  async (payload: string, thunkApi) => {
    try {
      const res = await apollo.mutate({
        mutation: DELETE_CITY,
        variables: {
          key: payload,
        },
      });
      if (res?.data?.deleteCity) {
        // @ts-ignore
        const cities = cloneDeep(thunkApi.getState()?.directory?.cities || []);
        const newData = cities.filter((item: CityType) => item.key !== payload);
        thunkApi.dispatch(setCities(newData));
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export default directoryModule.reducer;
