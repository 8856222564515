import moment, { Moment } from "moment";
import { getDateFormat } from "common/utils/date";
import { PaginationPayload } from "components/UI/Pagination";
import { ReportName } from "common/const/reports";
import {
  CallCenterFiltersState,
  CommonState,
  FiltersState,
  PilotsFiltersState,
} from "./types";

export const preparePayload = (
  state: FiltersState,
  pagination: PaginationPayload,
  reportName: ReportName
) => {
  const { dateFrom, dateTo } = state;
  const { departamentId, managerId, projectName } =
    state as CallCenterFiltersState;
  const { candidateName } = state as PilotsFiltersState;

  return {
    dateFrom: dateFrom
      ? getDateFormat(dateFrom, "YYYY-MM-DD")
      : getDateFormat(moment().subtract(1, "months"), "YYYY-MM-DD"),
    dateTo: dateTo
      ? getDateFormat(dateTo, "YYYY-MM-DD")
      : getDateFormat(moment(), "YYYY-MM-DD"),
    sendExcel: "true",
    offset: String(pagination.offset),
    limit: String(pagination.limit),
    ...(departamentId ? { departamentId: String(departamentId) } : {}),
    ...(managerId ? { managerId: String(managerId) } : {}),
    ...(projectName ? { projectName } : {}),
    ...(candidateName ? { candidateName } : {}),
    reportName,
  };
};

export const getDisabledDateFrom = (start: Moment, end: Moment) => {
  return (
    end.diff(start, "days") > MAX_DAY_PERIOD || start.diff(end, "days") >= 0
  );
};

export const getDisabledDateTo = (start: Moment, end: Moment) => {
  return moment().isSameOrBefore(end) || start.diff(end, "days") >= 0;
};

const MAX_DAY_PERIOD = 90;

const COMMON_INITIAL_STATE: CommonState = {
  dateFrom: moment().subtract(1, "months"),
  dateTo: moment(),
};

export const CALL_CENTER_INITIAL_STATE: CallCenterFiltersState = {
  ...COMMON_INITIAL_STATE,
  departamentId: null,
  managerId: null,
  projectName: "",
};

export const PILOTS_INITIAL_STATE: PilotsFiltersState = {
  ...COMMON_INITIAL_STATE,
  departamentId: null,
  managerId: null,
  projectName: "",
  vacancyId: "",
  vacancyName: "",
  candidateName: "",
};

export const stateByReportName: Record<ReportName, FiltersState> = {
  [ReportName.ReportCallCenter]: CALL_CENTER_INITIAL_STATE,
  [ReportName.ReportPilot]: PILOTS_INITIAL_STATE,
};
