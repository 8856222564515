import { ReportName, titleByReportName } from "common/const/reports";
import { Report } from "components/UI/icons";

type ReportCard = {
  title: string;
  icon: React.ReactElement;
  isVisible?: boolean;
  onClick: () => void;
};

export const getReportCards = (
  handleClick: (name: ReportName) => void
): ReportCard[] => [
  {
    title: titleByReportName[ReportName.ReportCallCenter],
    icon: <Report />,
    isVisible: true,
    onClick: () => handleClick(ReportName.ReportCallCenter),
  },
  {
    title: titleByReportName[ReportName.ReportPilot],
    icon: <Report />,
    isVisible: true,
    onClick: () => handleClick(ReportName.ReportPilot),
  },
];
