import React, { useEffect, useRef, useState } from "react";
import { Moment } from "moment";

import { CandidateStatus, RefusalReasonType } from "graphql/types/types";
import { Option } from "components/UI/Cascader";
import { ChevronForward } from "components/UI/icons";
import { useToggle } from "common/hooks/useToggle";

import "./styles.scss";

interface Props {
  done?: boolean;
  options: Option[];
  changeStatus: (status: string) => void;
  updateStatus?: (
    status: CandidateStatus,
    reason?: RefusalReasonType["id"] | null,
    date?: Moment | null
  ) => Promise<void>;
}

export const CustomDropDown: React.FC<Props> = ({
  options,
  done,
  changeStatus,
  updateStatus,
  children,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [open, toggle, setOpen] = useToggle(false);
  const [status, setStatus] = useState("");
  const [openChild, setOpenChild] = useState<string | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    toggle();
  };

  const handleChange = (e: React.MouseEvent<HTMLLIElement>, value: string) => {
    e.stopPropagation();

    if (
      value === CandidateStatus.Callback ||
      value === CandidateStatus.RefusalCandidate
    ) {
      setOpenChild(value);
      setStatus(value);
      changeStatus(value);
      return;
    }

    setOpenChild(null);
    updateStatus?.(value as CandidateStatus);
    setOpen(false);
  };

  const handleChildChange = (
    e: React.MouseEvent<HTMLLIElement>,
    value: number | string
  ) => {
    e.stopPropagation();

    if (value === "date") {
      return;
    }

    updateStatus?.(status as CandidateStatus, value as number);
    setOpen(false);
  };

  useEffect(() => {
    if (done) {
      setOpen(false);
    }
  }, [done, setOpen]);

  return (
    <div className="customDropDown" ref={dropdownRef}>
      <div className="customDropDown-trigger" onClick={handleClick}>
        {children}
      </div>

      {open && (
        <ul className="customDropDown-open customDropDown-open--parent">
          {options.map(({ label, value, children }, i) => (
            <li key={i} onClick={(e) => handleChange(e, value as string)}>
              {label}

              {children?.length && value === openChild ? (
                <ul className="customDropDown-open customDropDown-open--children">
                  {children.map((item, i) => (
                    <li
                      key={i}
                      onClick={(e) =>
                        handleChildChange(e, item.value as number)
                      }
                    >
                      {item.label}
                    </li>
                  ))}
                </ul>
              ) : null}

              {children?.length ? (
                <span>
                  <ChevronForward color="#000" />
                </span>
              ) : null}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
